import React, { useState } from "react";
import rooster from "./assets/logo.png";
// import { Link } from "react-router-dom";
import "./navbar.css";
const Navbar = () => {
  const [display, setDisplay] = useState(false);
  const [howToBuy, sethowToBuy] = useState(false);
  const [tokenomics, settokenomics] = useState(false);
  const [roadMap, setroadMap] = useState(false);
  const [community, setcommunity] = useState(false);

  const showDropDown = (e) => {
    console.log(e.target.innerText);
    sethowToBuy(!howToBuy);
    settokenomics(false);
    setroadMap(false);
    setcommunity(false);
  };
  const showTokenomicsDropDown = (e) => {
    console.log(e.target.innerText);
    settokenomics(!tokenomics);
    sethowToBuy(false);
    setroadMap(false);
    setcommunity(false);
  };
  const showRoadMapDropDown = (e) => {
    console.log(e.target.innerText);
    setroadMap(!roadMap);
    settokenomics(false);
    sethowToBuy(false);
    setcommunity(false);
  };
  const showComunityDropDown = (e) => {
    console.log(e.target.innerText);
    setcommunity(!community);
    settokenomics(false);
    sethowToBuy(false);
    setroadMap(false);
  };
  const hideDropDown = (e) => {
    setcommunity(false);
    settokenomics(false);
    sethowToBuy(false);
    setroadMap(false);
  };

  const idd = () => {
    console.log(document.getElementById("idd"));
  };

  return (
    <>
      <nav>
        <input checked={display} id="nav-toggle" type="checkbox" />
        <div class="logo">
          <img src={rooster} id="hulilogo"></img>
          Huli <strong style={{ color: "#00ff00" }}> Huli</strong>
        </div>

        <ul class="links nav__menu" id="hey" onClick={idd}>
          <li className="nav__menu-item">
            <a href="coming.html" onMouseOver={showDropDown} onMouseLeave={hideDropDown}>
              APPS <i class="arrow down"></i>
            </a>
            <ul className="nav__submenu">
              <li className="nav__submenu-item ">
                <a href="https://bsc.hulihuli.finance">ETH-HULI-Swap</a>
              </li>
              <li className="nav__submenu-item ">
                <a href="coming.html">BSC-HULI-Swap</a>
              </li>
              <li className="nav__submenu-item ">
                <a href="coming.html">$Huli-Rewards</a>
              </li>
            </ul>

            {/* <ul class={ howToBuy ? "nav-dropdown active" : "nav-dropdown"}>
                <li>
                  <a href="coming.html">A</a>
                </li>
                <li>
                  <a href="coming.html">B</a>
                </li>
                <li>
                  <a href="coming.html">C</a>
                </li>
              </ul> */}
          </li>
          <li className="nav__menu-item">
            <a href="coming.html" onClick={showTokenomicsDropDown}>
              COMMUNITY <i class="arrow down"></i>
            </a>
            <ul className="nav__submenu">
              <li className="nav__submenu-item ">
                <a href="coming.html">Telegram</a>
              </li>
              <li className="nav__submenu-item ">
                <a href="coming.html">Discord</a>
              </li>
              <li className="nav__submenu-item ">
                <a href="coming.html">Huli-Play</a>
              </li>
              <li className="nav__submenu-item ">
                <a href="coming.html">NFTs</a>
              </li>
            </ul>
          </li>
          <li className="nav__menu-item">
            <a href="coming.html" onClick={showRoadMapDropDown}>
              PRICE CHART <i class="arrow down"></i>
            </a>
            <ul className="nav__submenu">
              <li className="nav__submenu-item ">
                <a href="https://www.dextools.io/app/uniswap/pair-explorer/0xdc61a0cbd906f2b6b548aa31231017c622f80f40">Dextools</a>
              </li>
              <li className="nav__submenu-item ">
                <a href="coming.html">CoinmarketCap</a>
              </li>
              <li className="nav__submenu-item ">
                <a href="coming.html">CoinGecko</a>
              </li>
            </ul>
          </li>
          <li className="nav__menu-item">
            <a href="coming.html" onClick={showComunityDropDown}>
              TOKEN DETAILS<i class="arrow down"></i>
            </a>
            <ul className="nav__submenu">
              <li className="nav__submenu-item ">
                <a href="#stats">Tokenomics</a>
              </li>
              <li className="nav__submenu-item ">
                <a href="coming.html">Smart Contract</a>
              </li>
              <li className="nav__submenu-item ">
                <a href="coming.html">Security Audit</a>
              </li>
              <li className="nav__submenu-item ">
                <a href="whitepaper.pdf">WhitePaper</a>
              </li>
            </ul>
          </li>
          {/* <li className="buy-btn">
            <a
              id="w-node-_6be0c6f3-5804-18a3-23b0-d24feef2e80c-52018772"
              href="https://dex.hulihuli.finance"
              target="_blank" 
              className="re-button cta-button is--nav-link w-button"
            >
              BUY HuliHuli
            </a>
          </li> */}
        </ul>
        <ul class="linkobtn nav__menu">
        <li className="buy-btn">
            <a
              id="w-node-_6be0c6f3-5804-18a3-23b0-d24feef2e80c-52018772"
              href="https://dex.hulihuli.finance"
              target="_blank"
              className="re-button cta-button is--nav-link w-button"
            >
              BUY $Huli
            </a>
          </li>
        </ul>
        

        <label
          for="nav-toggle"
          class="icon-burger"
          onClick={() => setDisplay(!display)}
        >
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </label>
      </nav>
    </>
  );
};

export default Navbar;
